import React, { useState, useEffect, useContext } from "react";
import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";
import "../CheckoutProcess.css";
import Successfull_Lottie from "../../../components/Animation/Successfull_Lottie/Successfull_Lottie";
import { Modal } from "react-bootstrap";
import Cookies from "js-cookie";
import { useForm, Controller } from "react-hook-form";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import { Context } from "../../../utils/context";
import { CartQuantity, getCart } from "../../../utils/apis/addToCart/addToCart";
import {
  getCouponCode,
  Checkout,
} from "../../../utils/apis/orderProcess/orderProcess";
import useRazorpay from "react-razorpay";
import CouponModal from "../../AllModal/CouponModal/CouponModal";
import CoupanModal from "../../AllModal/coupon_modal/couponModal";

const FourConform = ({
  handlePrevious,
  handleNext,
  paymentMethod,
  addressId,
}) => {
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);
  const navigate = useNavigate();
  const { IMG_URL, signin, updateCartCount, triggerCartUpdate, cartCount } =
    useContext(Context);

  const {
    control,
    register,
    handleSubmit,
    getValues,
    setError,
    reset,
    formState: { errors },
  } = useForm();
  const [amount, setAmount] = useState(0);
  const [coupon, setCoupon] = useState("");
  const [discount, setDiscount] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [totalTax, setTotalTax] = useState(0);
  const [grandTotal, setGrandTotal] = useState(0);
  const [message, setMessage] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const [modalShow1, setModalShow1] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [couponStatus, setCouponStatus] = useState(false);
  const [cart, setCart] = useState([]);
  const [Razorpay, openRazorpay] = useRazorpay();
  const [razorid, setRazorId] = useState();
  const [cardError, setCardError] = useState();

  const [copyCode, setCopyCode] = useState("");

  console.log(copyCode, "copycode");

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    if (signin) {
      cartGet();
    } else {
      withoutCart();
    }
  }, [signin]);

  useEffect(() => {
    calculateCartTotals(cart);
    // triggerCartUpdate()
  }, [cart, discount]);

  const onSubmit = async (data) => {
    try {
      const res = await getCouponCode(data?.code, totalPrice);

      if (res?.success) {
        if (res?.couponCode) {
          setModalShow1(true);
          setCoupon(res?.couponCode?.id);
          setCouponStatus(true);
          const discountValue =
            res?.couponCode?.type === "fixedamount"
              ? res?.couponCode?.discount
              : (totalPrice * res?.couponCode?.discount) / 100;
          setDiscount(discountValue);
          setMessage(`Coupon Applied: ₹${discountValue}`);
          setError("code", {}); // Clear any previous errors
          setTimeout(() => {
            setModalShow1(false);
            setMessage("");
            setCardError("");
          }, 3000);
        } else {
          setDiscount(0);
          setCoupon("");
          setCouponStatus(false);
          setError("code", { type: "manual", message: "Invalid Coupon" });
        }
      } else {
        setDiscount(0);
        setCoupon("");
        setCouponStatus(false);
        setCardError(res?.message || "Invalid Coupon");
      }
    } catch (error) {
      setDiscount(0);
      setCoupon("");
      setCouponStatus(false);
      setCardError("An unexpected error occurred");
    }
  };

  const getCartQuantity = async (id, type) => {
    setDiscount(0);
    setCoupon("");
    setCouponStatus(false);
    setError("code", "");
    await CartQuantity(id, type);
    updateCartData();
  };

  const cartGet = async () => {
    const res = await getCart();
    if (res?.success) {
      updateCartData(res?.data);
    }
    setCart(res?.data);
  };

  const withoutCart = async () => {
    updateCartData();
  };

  const updateCartData = async (data = null) => {
    let cartData = data || JSON.parse(Cookies.get("purevia_cart") || "[]");
    if (cartData.length === 0) {
      navigate("/");
    } else {
      setCart(cartData);
      calculateCartTotals(cartData);
    }
  };

  const calculateCartTotals = (cartData) => {
    let totalPrice = 0;
    let totalTax = 0;
    cartData.forEach((item) => {
      const itemTotal = item?.product?.sale?.sale_price
        ? item?.product?.sale?.sale_price * item?.quantity
        : item?.product?.s_price * item?.quantity;
      const itemTax =
        (item?.product?.product_detail?.tax_percentage?.name / 100) * itemTotal;
      totalPrice += itemTotal;
      totalTax += itemTax;
    });
    setTotalPrice(totalPrice + totalTax + 40 - discount);
    setTotalTax(totalTax);
    setGrandTotal(totalPrice + totalTax + 40 - discount); // Assuming ₹40 for shipping
  };

  const handleRazorpay = async (storedValue) => {
    const options: RazorpayOptions = {
      key: "rzp_test_I05KCFb1v5JJRc",
      amount: Math.round(storedValue) * 100,
      currency: "INR",
      name: "Hiclousia",
      description: "Test Transaction",
      image:
        "http://localhost:3000/static/media/hippicart-logo.427718645d3015dbf003.png",

      handler: async (res) => {
        await setRazorId(res.razorpay_payment_id);
        finalFunction(res.razorpay_payment_id);
      },
      prefill: {
        name: "Piyush Garg",
        email: "youremail@example.com",
        contact: "9999999999",
      },
      notes: {
        address: "Razorpay Corporate Office",
      },
      theme: {
        color: "#052c65",
      },
    };

    const rzpay = new Razorpay(options);
    rzpay.open();
  };

  const finalFunction = async (razor = "") => {
    const data = {
      address_id: addressId,
      amount: totalPrice,
      discount: discount,
      payment_mode: razor !== "" ? "online" : "cod",
    };
    if (coupon) {
      data.code_id = coupon;
    }
    if (razor) {
      data.payment_id = razor;
    }

    const res = await Checkout(data);
    if (res?.success) {
      setModalShow(true);
      setTimeout(() => {
        setModalShow(false);
        updateCartCount();
        navigate("/my-account");
      }, 3000);
    }
  };
  // useEffect(() => {

  // }, [cartCount]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  console.log(cart,"cartlllllllllllll");
  return (
    <>
      <section className="checkout-inner-steps">
        <div className="conform-table">
          <div className="row justify-content-end">
            <div className="col-lg-12">
              <div className="first-table">
                <Table bordered>
                  <thead>
                    <tr>
                      <th>S. No.</th>
                      <th>Image</th>
                      <th>Item Name</th>
                      {/* <th>Variation</th> */}
                      <th>Quantity</th>
                      <th>Tax</th>
                      <th>MRP</th>
                      <th>Order Price</th>
                      <th>Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    {cart?.map((item, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>
                          <div className="main-img-div">
                            <div className="img-div">
                              <img
                                className="product-img"
                                src={IMG_URL + item?.product?.image1}
                                alt="Product"
                              />
                            </div>
                          </div>
                        </td>
                        <td>{item?.product?.name}</td>
                        {/* <td>{item?.color}</td>
                         */}
                        <td>{item?.quantity}</td>
                        <td>
                          {item?.product?.product_detail?.tax_percentage?.name}%
                        </td>
                        <td>₹{item?.product?.mrp}</td>
                        <td>
                          ₹
                          {item?.product?.sale?.sale_price
                            ? item?.product?.sale?.sale_price
                            : item?.product?.s_price}
                        </td>
                        <td>
                          ₹
                          {item?.product?.sale?.sale_price
                            ? (
                                item?.product?.sale?.sale_price *
                                  item?.quantity +
                                (parseInt(
                                  item?.product?.product_detail?.tax_percentage
                                    ?.name
                                ) *
                                  (item?.product?.sale?.sale_price *
                                    item?.quantity)) /
                                  100
                              ).toFixed(2)
                            : (
                                item?.product?.s_price * item?.quantity +
                                (parseInt(
                                  item?.product?.product_detail?.tax_percentage
                                    ?.name
                                ) *
                                  (item?.product?.s_price * item?.quantity)) /
                                  100
                              ).toFixed(2)}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-8">
              <div className="second-table my-4">
                <Table bordered>
                  <tbody>
                    <tr>
                      <th>Total amount</th>
                      <td>₹{totalPrice.toFixed(2)}</td>
                    </tr>
                    <tr>
                      <th>Coupon Discount</th>
                      <td>₹{discount}</td>
                    </tr>
                    <tr>
                      <th>
                        <Form.Group
                          className=""
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form>
                            <Form.Control
                              type="text"
                              name="code"
                              placeholder="Enter Code"
                              value={copyCode ? copyCode : ""}
                              {...register("code", {
                                required: "Code is required",
                              })}
                              className={classNames("", {
                                "is-invalid": errors?.code,
                                "is-valid": getValues("code"),
                              })}
                              onChange={() => {
                                setDiscount(0);
                                setCoupon("");
                                setCouponStatus(false);
                                setError("code", "");
                              }}
                            />
                            <span style={{ color: "red" }}>{cardError} </span>
                          </Form>
                        </Form.Group>
                      </th>
                      <td>
                        <div className="main-btn-div">
                          <button
                            className="save-btn"
                            type="button"
                            onClick={handleOpenModal}
                          >
                            Show Coupons
                          </button>
                          <CoupanModal
                            show={showModal}
                            setCopyCode={setCopyCode}
                            onHide={() => setShowModal(false)}
                            handleClose={handleCloseModal}
                          />
                        </div>
                        <div className="main-btn-div">
                          {couponStatus ? (
                            <button
                              className="save-btn"
                              type="button"
                              onClick={() => {
                                setDiscount(0);
                                setCoupon("");
                                setCouponStatus(false);
                                reset();
                              }}
                            >
                              Remove Coupon
                            </button>
                          ) : (
                            <div className="main-btn-div">
                              <button
                                className="save-btn"
                                type="button"
                                onClick={handleSubmit(onSubmit)}
                              >
                                Apply Coupon
                              </button>
                            </div>
                          )}
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th>Tax</th>
                      <td>₹{totalTax?.toFixed(2)}</td>
                    </tr>
                    <tr>
                      <th>Shipping</th>
                      <td>₹40.00</td>
                    </tr>
                    <tr>
                      <th>Grand Total</th>
                      <td>₹{grandTotal?.toFixed(2)}</td>
                    </tr>
                  </tbody>
                </Table>
                {/* <CouponModal
              show={showModal}
              onHide={() => setShowModal(false)}
              message={message}
            /> */}
              </div>
            </div>
          </div>
        </div>
        <div className="main-btn-div">
          <button className="back-btn" onClick={handlePrevious}>
            Back
          </button>

          {paymentMethod === "cod" ? (
            <button
              type="button"
              onClick={() => finalFunction()}
              className="save-btn"
            >
              Confirm Order
            </button>
          ) : (
            <button
              type="button"
              onClick={() => handleRazorpay(grandTotal)}
              className="save-btn"
            >
              Confirm Order
            </button>
          )}
        </div>
      </section>

      <div className="password-modal">
        <Modal
          className="pass-mod"
          show={modalShow}
          size="sm"
          onHide={() => setModalShow(false)}
          animation={false}
          centered
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <div className="succes">
              <Successfull_Lottie />
            </div>
            <div className="passwordsucces">
              <p>Your order has been placed successfully</p>
            </div>
          </Modal.Body>
        </Modal>
      </div>
      <div className="password-modal">
        <Modal
          className="pass-mod"
          show={modalShow1}
          size="sm"
          onHide={() => setModalShow1(false)}
          animation={false}
          centered
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <div className="succes">
              <Successfull_Lottie />
            </div>
            <div className="passwordsucces">
              <p>Coupon Applied Successfull</p>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export default FourConform;
